<template>
	<div class="customer-template-setting">
		<v-layout class="py-3 px-3">
			<v-flex class="">
				<div class="fw-600 sub-heading primary--text">API Credentials</div>
			</v-flex>
			<v-spacer></v-spacer>
			<v-flex class="d-flex justify-content-end">
				<v-btn class="" dense tile v-on:click="goBack()">
					<v-icon class="ml-1">mdi-arrow-left</v-icon>
					Back
				</v-btn>
			</v-flex>
		</v-layout>

		<v-layout>
			<v-tabs
				v-model="Google_CredTab"
				@change="toggleTab"
				color="blue darken-4"
				active-class="blue darken-4 white--text"
			>
				<v-tab href="#cred-google-analytics">Google Analytics</v-tab>
				<v-tab href="#cred-google-search-console">Google Search Console</v-tab>
				<v-tab href="#quick-book">Quick Book</v-tab>
				<v-tab href="#google-calendar">Google Calendar</v-tab>
				<v-tab href="#fetch-url-status">Fetch URL Status</v-tab>
			</v-tabs>
		</v-layout>

		<v-layout class="mt-2">
			<v-tabs-items class="w-100" v-model="Google_CredTab">
				<v-tab-item :value="element.value">
					<component
						:is="element.component"
						v-bind="{ ...element.props, pageLoading }"
						@refresh="refreshData"
						@toggle-pageLoading="pageLoading = $event"
					></component>
				</v-tab-item>
				<v-tab-item value="quick-book">
					<h1 class="text-muted">Quick Book</h1>
				</v-tab-item>
				<v-tab-item value="google-calendar">
					<h1 class="text-muted">Google Calendar</h1>
				</v-tab-item>
				<v-tab-item value="fetch-url-status">
					<h1 class="text-muted">Fetch URL Status</h1>
				</v-tab-item>
			</v-tabs-items>
		</v-layout>
	</div>
</template>
<script>
import { GET /* , PATCH */ } from "@/core/services/store/request.module";
/* import { SET_MESSAGE } from "@/core/services/store/common.module"; */

export default {
	name: "GoogleCredentials",
	components: { ...dynamicTabComponents() },
	data() {
		return {
			google_analytics: [],
			google_search_console: [],
			pageLoading: false,
			element: {
				component: dynamicTabComponents("GoogleAnalyticsCredentials"),
				value: "cred-google-analytics",
				props: { google_analytics: [], google_search_console: [] },
			},
		};
	},
	methods: {
		goBack() {
			this.$router.go(-1);
		},
		getGoogleAnalyticsCredentials() {
			this.pageLoading = true;
			this.$store
				.dispatch(GET, { url: `analytic-credentials` })
				.then((data) => {
					if (data && Array.isArray(data) && data.length) {
						this.google_analytics = [...data];
						this.alterProps();
					}
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getGoogleSearchCnsoleCredentials() {
			this.pageLoading = true;
			this.$store
				.dispatch(GET, { url: `gsc-credentials` })
				.then((data) => {
					if (data && Array.isArray(data) && data.length) {
						this.google_search_console = [...data];
						this.alterProps();
					}
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		toggleTab: function (tab) {
			const { query, name, params } = this.$route;
			this.$router.push({ name, query: { ...query, subTab: tab }, params });
		},
		alterProps: function () {
			if (this.Google_CredTab == "cred-google-analytics") {
				this.element.props = { ...this.element.props, google_analytics: this.google_analytics };
			} else if (this.Google_CredTab == "cred-google-search-console") {
				this.element.props = {
					...this.element.props,
					google_search_console: this.google_search_console,
				};
			}
		},
		refreshData: function (type) {
			if (type == "analytics") {
				this.getGoogleAnalyticsCredentials();
			} else if (type == "gsc") {
				this.getGoogleSearchCnsoleCredentials();
			}
		},
		initialLoad: function () {
			this.getGoogleAnalyticsCredentials();
			this.getGoogleSearchCnsoleCredentials();
		},
	},
	computed: {
		Google_CredTab: {
			get() {
				const { value } = this.element;
				return value;
			},
			set(tab) {
				this.element.value = tab;
				const analytics = this.google_analytics;
				const gsc_console = this.google_search_console;
				const elementToMount = this.element;
				switch (tab) {
					case "cred-google-analytics":
						elementToMount.component = dynamicTabComponents("GoogleAnalyticsCredentials");
						elementToMount.props = { ...elementToMount.props, google_analytics: analytics };
						break;
					case "cred-google-search-console":
						elementToMount.component = dynamicTabComponents("GoogleSearchConsoleCredentials");
						elementToMount.props = { ...elementToMount.props, google_search_console: gsc_console };
						break;
				}
			},
		},
	},
	beforeMount() {
		const { query } = this.$route;
		this.Google_CredTab = query.subTab || "cred-google-analytics";
	},
	mounted() {
		this.initialLoad();
	},
};

function dynamicTabComponents(type = null) {
	const components = {
		GoogleAnalyticsCredentials: () => import("./Tabs/GoogleAnalyticsCredentials.vue"),
		GoogleSearchConsoleCredentials: () => import("./Tabs/GoogleSearchConsoleCredentials.vue"),
	};
	if (type) {
		return components[type];
	}
	return components;
}
</script>
